import { RxJsonSchema } from 'rxdb';

const schema: RxJsonSchema = {
    title: 'order appointment schema',
    description: 'no description',
    version: 0,
    keyCompression: false,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
        },
        orderId: {
            type: 'string',
        },
        appointmentType: {
            type: 'number',
        },
        status: {
            type: 'number',
        },
        dates: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    dateType: {
                        type: 'number',
                    },
                    dateUnix: {
                        type: 'number',
                    },
                    isEditable: {
                        type: 'boolean',
                    },
                },
            },
        },
        filterableDate: {
            type: 'number',
        },
        roNumber: {
            type: 'string',
        },
        dealerId: {
            type: 'string',
        },
        customer: {
            type: 'object',
            properties: {
                name: {
                    type: 'string',
                },
                address1: {
                    type: 'string',
                },
                address2: {
                    type: 'string',
                },
                city: {
                    type: 'string',
                },
                state: {
                    type: 'string',
                },
                zip: {
                    type: 'string',
                },
                countryCode: {
                    type: 'string',
                },
                phone: {
                    type: 'string',
                },
                email: {
                    type: 'string',
                },
            },
        },
        actionsRequired: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'number',
                    },
                    description: {
                        type: 'string',
                    },
                },
            },
        },
    },
    required: ['id', 'orderId'],
    indexes: ['filterableDate'],
    encrypted: [
        'customer.name',
        'customer.address1',
        'customer.address2',
        'customer.city',
        'customer.state',
        'customer.zip',
        'customer.countryCode',
        'customer.phone',
        'customer.email',
    ],
};
export default schema;
