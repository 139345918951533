export enum ActionRequired {
    CfiToEnterActualInstallDateDeliveryDate = 145,
    CfiToUploadCocPod = 160,
    WOSageToSelectRO = 406,
    WOCfiToContactCustomer = 410,
    WOCfiToScheduleAppointment = 420,
    WOCfiToCompleteWorkOrder = 430,
    WOCfiToUploadWOC = 440,
    WOReadyForClosure = 490,
}
