import { IComparable } from '@shared/utils';
import { isEqual } from 'lodash';
import { AppointmentDateTypes } from './appointments-date-types.enum';

export class AppointmentsSearchParams implements IComparable<AppointmentsSearchParams> {
    public keywords?: string;
    public dateTypes?: AppointmentDateTypes;
    public jobTypes?: number[];
    public retailers?: string[];

    constructor(p: Partial<AppointmentsSearchParams>) {
        this.dateTypes = p.dateTypes;
        this.keywords = p.keywords;
        this.jobTypes = p.jobTypes;
        this.retailers = p.retailers;
    }

    public compare(comparingInstance: AppointmentsSearchParams): boolean {
        return isEqual(this, comparingInstance);
    }
}
