import { AbstractControl, ValidatorFn } from '@angular/forms';

/*
 * Validator function for checking the validity of form control by testing the value
 * of a regexp input. The calling entity should send /false/ or /true/, in order for this to work
 */
export let fieldValidatorByLogical = (condition: RegExp): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const forbidden = condition.test('false');
        return forbidden ? { fieldValidatorFailed: true } : null;
    };
};
