import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { RefreshTokenInterceptor } from './refresh-token.interceptor';
import { RefreshTokenProviderBase } from './refresh-token-provider-base';
import { TokenInterceptor } from './token-interceptor';
import { TokenProvider } from './token-provider';

@NgModule({
    imports: [CommonModule],
})
export class SharedTokenInterceptorModule {
    public static forRoot(config: {
        tokenProvider: Type<TokenProvider>;
        refreshTokenProvider: Type<RefreshTokenProviderBase>;
    }): ModuleWithProviders<SharedTokenInterceptorModule> {
        return {
            ngModule: SharedTokenInterceptorModule,
            providers: [
                {
                    multi: true,
                    provide: HTTP_INTERCEPTORS,
                    useClass: TokenInterceptor,
                },
                {
                    multi: true,
                    provide: HTTP_INTERCEPTORS,
                    useClass: RefreshTokenInterceptor,
                },
                {
                    provide: TokenProvider,
                    useClass: config.tokenProvider,
                },
                {
                    provide: RefreshTokenProviderBase,
                    useClass: config.refreshTokenProvider,
                },
            ],
        };
    }
}
