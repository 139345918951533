import { Injectable } from '@angular/core';
import { AuthenticationResult } from '@shared/token-interceptor';
import jwt_decode from 'jwt-decode';

@Injectable()
export class JwtDecoderService {
    public jwtDecode(jwt: AuthenticationResult, field: string): string {
        let decodedJwt = JSON.stringify(jwt_decode(JSON.stringify(jwt.token)));

        return JSON.parse(decodedJwt)[field.toString()];
    }
}
