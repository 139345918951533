import { AppointmentStatus } from './appointment-status.enum';

export function appointmentStatusToString(appointmentStatus: AppointmentStatus): string {
    switch (appointmentStatus) {
        case AppointmentStatus.Open:
            return 'Open';
        case AppointmentStatus.Completed:
            return 'Completed';
        case AppointmentStatus.Canceled:
            return 'Canceled';
    }
}
