import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@domain/auth';
import { SyncGuard } from '@domain/sync';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'sync-progress',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'appointments',
        loadChildren: () =>
            import('./pages/appointments/pages-appointments.module').then((m) => m.PagesAppointmentModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: 'sync-progress',
        loadChildren: () => import('./pages/sync-progress/sync-progress.module').then((m) => m.SyncProgressPageModule),
        canActivate: [AuthGuardService, SyncGuard],
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
        canActivate: [AuthGuardService],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
