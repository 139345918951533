import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { UserStorageService } from './user-storage.service';
import { DynatraceLoggerService } from '../../sync/services/dynatrace-logger.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        private authService: AuthService,
        private navController: NavController,
        private router: Router,
        private traceService: DynatraceLoggerService,
        private userStorageService: UserStorageService
    ) {}

    public canActivate(): UrlTree | boolean {
        if (!this.authService.isAuthenticated()) {
            this.navController.navigateRoot('/login');

            return false;
        }
        this.traceService.log(
            'INFO',
            `User ${this.userStorageService.getUsername()} left module ${this.router.url}`,
            'Gimbil'
        );
        return true;
    }
}
