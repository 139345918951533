export enum AppointmentType {
    StoreVisit = 1,
    Measure = 2,
    Template = 3,
    Install = 4,
    Delivery = 5,
    WorkOrder = 6,
}

export function appointmentTypeToString(appointmentType: AppointmentType): string {
    switch (appointmentType) {
        case AppointmentType.StoreVisit:
            return 'Store Visit';
        case AppointmentType.Measure:
            return 'Measure';
        case AppointmentType.Template:
            return 'Template';
        case AppointmentType.Install:
            return 'Install';
        case AppointmentType.Delivery:
            return 'Delivery';
        case AppointmentType.WorkOrder:
            return 'Work Order';
    }
}
