import { Injectable } from '@angular/core';
import { RetailerApi, RetailerDto } from '@domain/appointment';
import { Retailer, RetailersRepository } from '@domain/database';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { partition } from 'lodash';
import { SyncBaseService } from './sync-base.service';
import { DynatraceLoggerService } from './dynatrace-logger.service';
import { UserStorageService } from '@domain/auth';

@Injectable()
export class RetailerSyncService extends SyncBaseService {
    private syncInProgress = false;

    constructor(
        private retailerRepo: RetailersRepository,
        private retailerService: RetailerApi,
        private traceService: DynatraceLoggerService,
        private userStorageService: UserStorageService
    ) {
        super();
    }

    public async startSyncing(): Promise<void> {
        if (this.syncInProgress) {
            return;
        }
        this.doneSubject.next(false);
        this.syncInProgress = true;
        // tslint:disable-next-line: no-console
        //console.time('/RETAILERS api duration');
        let lastModifiedUnix = await this.retailerRepo.getMaxModifiedUnix();
        // the API returns greater AND equal, so we add one more millisecond
        let lastModified = lastModifiedUnix ? fromUnixTime(lastModifiedUnix + 1) : undefined;

        const retailers = await this.retailerService.getAllRetailers(lastModified).toPromise();
        // tslint:disable-next-line: no-console
        //console.timeEnd('/RETAILERS api duration');

        // tslint:disable-next-line: no-console
        //console.time('/RETAILERS rxdb duration');
        await this.syncLocalRetailers(retailers);
        this.syncInProgress = false;
        this.doneSubject.next(true);
        // tslint:disable-next-line: no-console
        //console.timeEnd('/RETAILERS rxdb duration');
    }

    private async syncLocalRetailers(items: RetailerDto[]) {
        const [inactiveRetailers, activeRetailers] = partition(items, (item) => item.isInactive);

        if (activeRetailers.length === 0) {
            return;
        }
        // todo: delete inactive retailers

        const convertedRetailers = activeRetailers.map<Retailer>((r) => {
            return {
                id: `${r.id}_${r.environment}`,
                environment: r.environment ? r.environment : undefined,
                retailerId: r.id,
                name: r.name ? r.name : undefined,
                retailerCode: r.retailerCode ? r.retailerCode : undefined,
                address1: r.address1 ? r.address1 : undefined,
                address2: r.address2 ? r.address2 : undefined,
                zip: r.zip ? r.zip : undefined,
                city: r.city ? r.city : undefined,
                state: r.state ? r.state : undefined,
                countryCode: r.countryCode ? r.countryCode : undefined,
                phone: r.phone ? r.phone : undefined,
                email: r.email ? r.email : undefined,
                modifiedUnix: getUnixTime(new Date(r.modified)),
                storeNumber: r.storeNumber ? r.storeNumber : undefined,
            };
        });
        // .filter((r) => {
        //     return fromUnixTime(r.modifiedUnix).getFullYear() < 2020;
        // });

        this.traceService.log(
            'INFO',
            `User ${this.userStorageService.getUsername()} syncing local retailers`,
            'Gimbil'
        );

        await this.retailerRepo.bulkUpsert(convertedRetailers);
    }
}
