import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiExceptionHandlerService, HttpService } from './services';
import { HttpErrorInterceptor } from './interceptors';
import { MaintenanceInterceptor } from './interceptors/maintenance.interceptor';

@NgModule({
    declarations: [],
    imports: [CommonModule, HttpClientModule],
})
export class SharedHttpModule {
    public static forRoot(): ModuleWithProviders<SharedHttpModule> {
        return {
            ngModule: SharedHttpModule,
            providers: [
                HttpService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpErrorInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: MaintenanceInterceptor,
                    multi: true,
                },
                ApiExceptionHandlerService,
            ],
        };
    }
}
