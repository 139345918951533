import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConnectivityService } from './services';
import { ConnectivityStateService } from './services/connectivity-state.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    exports: [],
})
export class SharedNetworkConnectivityModule {
    public static forRoot(): ModuleWithProviders<SharedNetworkConnectivityModule> {
        return {
            ngModule: SharedNetworkConnectivityModule,
            providers: [ConnectivityService, ConnectivityStateService],
        };
    }
}
