import { AppointmentDateTypes, AppointmentStatus } from '../../models';
import { QueryExtender } from '../query.extender';
import { getUnixTime, startOfToday, startOfTomorrow } from 'date-fns';
import { QueryModel } from '../query-extender.model';
import { MangoQuerySortDirection } from 'rxdb';

export class TodayDateQueryExtender implements QueryExtender {
    private readonly dateType: AppointmentDateTypes | null;
    private readonly tomorrowUnixTimestamp: number;
    private readonly currentUnixTimestamp: number;

    constructor(dateType: AppointmentDateTypes | null) {
        this.dateType = dateType;
        this.tomorrowUnixTimestamp = getUnixTime(startOfTomorrow()) - 1;
        this.currentUnixTimestamp = getUnixTime(startOfToday()) - 1;
    }

    public extend(finalQuery: QueryModel) {
        if (Number(this.dateType) === AppointmentDateTypes.Today) {
            const rangeCondition = {
                status: { $in: [AppointmentStatus.Open] },
                filterableDate: { $lt: this.tomorrowUnixTimestamp, $gt: this.currentUnixTimestamp },
            };

            finalQuery.query.selector.$and.push(rangeCondition);
            finalQuery.sort.filterableDate = 'asc' as MangoQuerySortDirection;
        }

        return finalQuery;
    }
}
