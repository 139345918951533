import { Injectable } from '@angular/core';
import { ApiVersions, HttpService, IApi } from '@shared/http';
import { formatISO } from 'date-fns';
import { Observable } from 'rxjs';
import { RetailerDto } from '../models/retailer.dto';

@Injectable()
export class RetailerApi implements IApi {
    public endpoint = `api/${ApiVersions.V3}/retailers`;

    constructor(private httpService: HttpService) {}

    public getAllRetailers(modifiedAfter?: Date): Observable<RetailerDto[]> {
        const queryParams = modifiedAfter ? `?modified=${encodeURIComponent(formatISO(modifiedAfter))}` : '';
        return this.httpService.makeGet<RetailerDto[]>(`${this.endpoint}${queryParams}`);
    }
}
