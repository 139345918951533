import { Injectable } from '@angular/core';
import { HttpService } from '@shared/http';
import { AuthenticationResult } from '@shared/token-interceptor';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap, tap } from 'rxjs/operators';
import { LoginModel } from '../models';
import { AuthenticationTokenProvider } from '../providers/authentication-provider';
import { DatabaseProvider } from '@domain/database';
import { LoadingController, NavController } from '@ionic/angular';
import { UserStorageService } from './user-storage.service';
import { AlertService } from '@shared/alert';
import { TranslateService } from '@ngx-translate/core';
import { DeviceIdService } from './deviceid.service';
import { ChangePasswordModel } from '../models/change-pasword.model';
import { ForgotPasswordModel } from '../models/forgot-password.model';
import { CheckAnswerPasswordModel } from '../models/check-answer-password.model';

@Injectable()
export class AuthService {
    public isAuthenticated$ = this.tokenProvider.tokenChanged$.pipe(map((auth) => auth !== null));
    private authenticateEndpoint = 'auth';

    public constructor(
        private httpService: HttpService,
        private tokenProvider: AuthenticationTokenProvider,
        private databaseProvider: DatabaseProvider,
        private navController: NavController,
        private userStorageService: UserStorageService,
        private alertService: AlertService,
        private translate: TranslateService,
        private loadingController: LoadingController,
        private deviceIdService: DeviceIdService
    ) {}

    public isAuthenticated(allowLogout: boolean = true): boolean {
        const authResult: AuthenticationResult = this.tokenProvider.getToken();

        if (allowLogout && authResult == null) {
            this.logout();
        }

        // after implementing 2FA, when we submit credentials for login we get a temporary token and refresh token is null
        // only after the user is filling the verification code we get the token and refresh token needed for API calls
        return !!(!!authResult && authResult.refreshToken);
    }

    public async logoutWithConfirmation() {
        const yesButton = {
            text: 'Yes',
            handler: async () => {
                const loader = await this.loadingController.create();
                await loader.present();
                await this.logout();
                loader.dismiss();
            },
        };
        const stayButton = {
            text: 'No',
            handler: () => {},
        };

        await this.alertService.presentAlert(
            '',
            this.translate.instant('login.logoutConfirmation'),
            [yesButton, stayButton],
            true
        );
    }

    public async logout(isOnMaintenanance: boolean = false) {
        this.tokenProvider.removeToken();
        this.userStorageService.clearUserInfo();
        await this.databaseProvider.clearDatabase();

        isOnMaintenanance
            ? this.navController.navigateRoot(`login`, { state: { isOnMaintenance: true } })
            : this.navController.navigateRoot('login');
    }

    public login(loginModel: LoginModel): Observable<AuthenticationResult> {
        return this.deviceIdService.getDeviceId().pipe(
            switchMap((deviceID) => {
                loginModel.deviceID = deviceID;
                return this.httpService.makePost(`${this.authenticateEndpoint}/authenticate`, loginModel).pipe(
                    tap((authenticationResult: AuthenticationResult) => {
                        this.tokenProvider.saveToken(authenticationResult);
                        this.userStorageService.setUsername(loginModel.userName);
                    })
                );
            })
        );
    }

    public changePassword(model: ChangePasswordModel): Observable<any> {
        return this.httpService.makePost(`${this.authenticateEndpoint}/changePassword`, model);
    }

    public forgotPassword(model: ForgotPasswordModel): Observable<any> {
        return this.httpService.makePost(`${this.authenticateEndpoint}/forgotPassword`, model);
    }

    public checkAnswer(model: CheckAnswerPasswordModel): Observable<any> {
        return this.httpService.makePost(`${this.authenticateEndpoint}/checkAnswer`, model);
    }
}
