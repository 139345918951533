import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { Observable, Subscription } from 'rxjs';
import { UserInfo } from '@domain/database';
import { timeout } from 'rxjs/operators';
import { SecurityQuestion } from '../../database/models/security-question.model';

@Injectable()
export class UserStorageService {
    private USERNAME_KEY = 'USER_NAME';
    private FULLNAME_KEY = 'USER_FULLNAME';
    private userEndpoint = `api/${ApiVersions.V3}/user`;
    private userInfoRequested: boolean;
    private userInfo: UserInfo;

    public constructor(private httpService: HttpService) {}

    public updateUserInfo(updatedUser: UserInfo): Observable<UserInfo> {
        return this.httpService.makePatch<UserInfo, UserInfo>(`${this.userEndpoint}/update`, updatedUser);
    }

    // fetchUserInfo from the api, keep in memory only, re-fetch on startup or new authentication
    public async fetchUserInfo() {
        if (!this.userInfoRequested && !this.userInfo) {
            this.userInfoRequested = true;
            await this.httpService
                .makeGet(`${this.userEndpoint}`)
                .toPromise()
                .then(
                    (res: UserInfo) => {
                        console.log('get complete', res);
                        this.userInfo = res;
                        this.setFullname(res.firstName, res.lastName);
                        localStorage.setItem('USERINFO', JSON.stringify(this.userInfo));
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    public getSecurityQuestionList(): Observable<SecurityQuestion[]> {
        return this.httpService.makeGet<SecurityQuestion[]>(`${this.userEndpoint}/listSecurityQuestions`);
    }

    public fetchUserList(): Observable<UserInfo[]> {
        return this.httpService.makeGet<UserInfo[]>(`${this.userEndpoint}/list`);
    }

    public getUserInfo(): UserInfo {
        if (!this.userInfo) {
            this.fetchUserInfo();
            this.userInfo = JSON.parse(localStorage.getItem('USERINFO'));
        }
        return this.userInfo;
    }

    public clearUserInfo(): void {
        this.userInfo = null;
        this.userInfoRequested = false;
    }

    public setFullname(firstName?: string, lastName?: string): string {
        let fullName: string = (firstName ? firstName : '') + (lastName ? ` ${lastName}` : '');
        this.userInfo.fullName = fullName;
        localStorage.setItem(this.FULLNAME_KEY, fullName);
        return fullName;
    }

    public getFullname(): string {
        return localStorage.getItem(this.FULLNAME_KEY);
    }

    public setUsername(username: string): void {
        localStorage.setItem(this.USERNAME_KEY, username);
    }

    public getUsername(): string {
        return localStorage.getItem(this.USERNAME_KEY);
    }
}
