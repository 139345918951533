import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatabaseModule } from '@domain/database';
import { DomainAppointmentDomainModule } from '@domain/appointment';
import { DomainProductModule } from '@domain/product';
import { DomainAuthModule } from '@domain/auth';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SharedUtilsModule } from '@shared/utils';
import { SharedHttpModule } from '@shared/http';
import { SharedNetworkConnectivityModule } from '@shared/network-connectivity';
import { AlertModule } from '@shared/alert';
import { DomainFeedbackModule } from '@domain/feedback';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        DatabaseModule,
        DomainAppointmentDomainModule.forRoot(),
        DomainProductModule.forRoot(),
        DomainAuthModule.forRoot(),
        SharedUtilsModule.forRoot(),
        IonicStorageModule.forRoot(),
        SharedHttpModule.forRoot(),
        SharedNetworkConnectivityModule.forRoot(),
        AlertModule.forRoot(),
        DomainFeedbackModule,
    ],
})
export class DomainCoreModule {}
