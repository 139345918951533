import { AppointmentDate, AppointmentStatus, AppointmentType, OrderAppointment } from '@domain/database';
import { APPOINTMENT_UNSCHEDULED, AppointmentDateTypes } from '../models';
import { Injectable } from '@angular/core';
import { fromUnixTime, getUnixTime, startOfToday } from 'date-fns';
import { AppointmentCompleteDateHandler, AppointmentDateHandler, AppointmentDatesHandler } from './handlers';

@Injectable()
export class JobDatesProvider {
    public getDateForJob(job: OrderAppointment): Date | number {
        if (job?.filterableDate === APPOINTMENT_UNSCHEDULED) {
            return job.filterableDate;
        }

        return fromUnixTime(job?.filterableDate);
    }

    public getDateForJobHandleUnscheduled(job: OrderAppointment): Date | number {
        if (
            this.getAppointmentDate(job.appointmentType, job.dates, AppointmentDateTypes.APPOINTMENT_DATE) ===
            APPOINTMENT_UNSCHEDULED
        ) {
            return APPOINTMENT_UNSCHEDULED;
        }

        return this.getDateForJob(job);
    }

    public isJobOverdue(job: OrderAppointment): boolean {
        const currentUnixTimestamp = getUnixTime(startOfToday()) - 1;
        return (
            job?.status === AppointmentStatus.Open &&
            job?.filterableDate < currentUnixTimestamp &&
            job?.filterableDate !== APPOINTMENT_UNSCHEDULED
        );
    }

    public getAppointmentDate(
        appointmentType: AppointmentType,
        appointmentDates: AppointmentDate[],
        dateType: AppointmentDateTypes
    ): number | null {
        const datesHandler = new AppointmentDatesHandler();
        const dateHandler = new AppointmentDateHandler();
        const completeDateHandler = new AppointmentCompleteDateHandler();

        datesHandler.setNext(dateHandler).setNext(completeDateHandler);
        return datesHandler.handle(appointmentType, appointmentDates, dateType);
    }
}
