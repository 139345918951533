import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { AutoSyncService, SyncConfigurationService } from '@domain/sync';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ConnectivityService, ConnectivityStateService } from '@shared/network-connectivity';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { DynatraceRumService } from './domain/sync/services/dynatrace-rum.service';
import { environment } from 'src/environments/environment';
import { AuthService, UserStorageService } from '@domain/auth';
import { DynatraceLoggerService } from './domain/sync/services/dynatrace-logger.service';

declare var dT_;
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
    public isOffline: boolean;
    private isOfflineSubscription: Subscription;

    constructor(
        private http: HttpClient,
        private platform: Platform,
        private statusBar: StatusBar,
        private translate: TranslateService,
        private connectivityService: ConnectivityService,
        private syncConfigurationService: SyncConfigurationService,
        private connectivityStateService: ConnectivityStateService,
        private dynatraceRumService: DynatraceRumService,
        private traceService: DynatraceLoggerService,
        private router: Router,
        private authService: AuthService,
        private userStorageService: UserStorageService,
        private autoSyncService: AutoSyncService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.initializeDynatrace(http);
        this.subscribeToNetworkStatus();
        this.initializeApp();
        this.subscribeToRouterEvents();
    }

    private subscribeToRouterEvents() {
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd && this.authService.isAuthenticated(false)) {
                this.traceService.log(
                    'INFO',
                    `User ${this.userStorageService.getUsername()} loaded page ${this.router.url}`,
                    'Gimbil'
                );
            }
        });
    }

    private initializeDynatrace(http: HttpClient) {
        if (environment.production) {
            this.dynatraceRumService.useGetDynatraceRumPromise().then((res) => {
                injectScriptTag(res);
            });
        }
        if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
            dT_.initAngularNg(http, Headers);
        }
    }

    public ngAfterViewInit(): void {
        SplashScreen.hide();
    }

    public ngOnDestroy(): void {
        this.isOfflineSubscription.unsubscribe();
    }

    public initializeApp() {
        this.syncConfigurationService.configureSync();
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
        });

        this.translate.setDefaultLang('en');
        this.connectivityService.startCheckConnectivity();
        this.autoSyncService.startAutoSync(60000 * 5, ['wifi']);
    }

    private subscribeToNetworkStatus() {
        this.isOfflineSubscription = this.connectivityStateService.connectivity$
            .pipe(map((status) => status.isConnected !== true))
            .subscribe((isOffline) => {
                this.isOffline = isOffline;
                if (isOffline) {
                    this.document.body.classList.add('offline-device');
                } else {
                    this.document.body.classList.remove('offline-device');
                }
            });
    }
}

function injectScriptTag(scriptText: string) {
    const scriptTag = document.createElement('script');
    let scriptUrl = scriptText.substring(scriptText.indexOf('src=') + 5, scriptText.indexOf('.js') + 3);
    scriptTag.type = 'text/javascript';
    scriptTag.src = scriptUrl;
    scriptTag.crossOrigin = 'anonymous';
    document.head.appendChild(scriptTag);
}
