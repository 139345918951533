import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { FeedbackDto } from '../dto';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root',
})
export class FeedbackApiService {
    private apiEndpoint = `api/${ApiVersions.V3}/feedback`;

    constructor(private httpService: HttpService) {}

    public postFeedback(fbType: string, fbMessage: string): Observable<void> {
        const feedback: FeedbackDto = {
            feedbackType: fbType,
            message: fbMessage,
        };

        return this.httpService.makePost(this.apiEndpoint, feedback);
    }
}
