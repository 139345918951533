import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CocAgreementDto } from '../models/coc-agreement.dto';
import { AppointmentOrder } from '../models';

@Injectable()
export class CocAgreementService {
    private urlPath;

    constructor(private http: HttpClient) {}

    public getCocAgreement$(appointment: AppointmentOrder): Observable<CocAgreementDto> {
        // IME Orders must get a different COC agreement, only differentiator is the RONumber
        // Case Insensitive, just in case
        if (appointment.roNumber.match(/^I\-/i)) {
            this.urlPath = 'assets/json/ime_coc_agreement.json';
        } else {
            this.urlPath = 'assets/json/coc_agreement.json';
        }
        return this.http.get<CocAgreementDto>(this.urlPath);
    }
}
