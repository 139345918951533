import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class DynatraceLoggerService {
    private baseUrl = 'https://znr05085.live.dynatrace.com';
    private headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization:
            'API-Token dt0c01.SDOJ7BKJOQY7ZNMTZJJPY6MT.HTC5IDZWG5VYWHDZBGDRSV5XMXJ66CSSC4NFANCJBYXYCFSTH6AUIKOS5L23A4HH',
    });

    constructor(private http: HttpClient) {}

    public log(type: string, message: string, origin: string) {
        if (!environment.production) {
            console.log(message);
            return;
        }
        this.ingestLog(this.getBody(type, message, origin), this.headers);
    }

    private getBody(type: string, message: string, origin: string) {
        return {
            severity: type,
            content: message,
            'log.source': origin,
        };
    }

    private ingestLog(body: object, headers: HttpHeaders) {
        this.http.post(`${this.baseUrl}/api/v2/logs/ingest`, body, { headers: headers }).subscribe({
            next: (success) => {},
            error: (error: HttpErrorResponse) => {
                console.log(error);
            },
        });
    }
}
