import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { SyncService } from '../services';

@Injectable()
export class SyncGuard implements CanActivate {
    constructor(private syncService: SyncService, private navController: NavController) {}

    public async canActivate(): Promise<boolean> {
        const wasInitialSyncDone = await this.syncService.wasInitialSyncDone();
        if (wasInitialSyncDone) {
            this.navController.navigateRoot('/dashboard');

            return false;
        }

        return true;
    }
}
