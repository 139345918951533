export enum AppointmentDetailsSlidesType {
    Details = 1,
    Forms = 2,
    Files = 3,
}

export function appointmentDetailsSlidesTypeToString(
    appointmentDetailsSlidesType: AppointmentDetailsSlidesType
): string {
    switch (appointmentDetailsSlidesType) {
        case AppointmentDetailsSlidesType.Details:
            return 'Details';
        case AppointmentDetailsSlidesType.Forms:
            return 'Forms';
        case AppointmentDetailsSlidesType.Files:
            return 'Files';
    }
}
