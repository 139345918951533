declare var require: any;

export class VersionService {
    public getVersion(): string {
        return require('../../../../../package.json').version + this.getBuildInfo();
    }

    public getBuildInfo(): string {
        const commit = require('../../../../commit.json').commit.substring(0, 6);
        return !commit ? '' : ' (' + commit.substring(0.6) + ')';
    }
}
