import { Injectable } from '@angular/core';
import { ApiVersions, HttpService, IApi } from '@shared/http';
import { formatISO } from 'date-fns';
import { Observable } from 'rxjs';
import { AppointmentSaveDateDto, OrderDto } from '../models';
import { EnvironmentType, KindOfOrder } from '@domain/database';

@Injectable()
export class OrderService implements IApi {
    public endpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public getAllOrders(dateAfter?: Date, modifiedAfter?: Date): Observable<OrderDto[]> {
        let queryParams = '?';
        if (dateAfter) {
            queryParams += `date=${encodeURIComponent(formatISO(dateAfter))}&`;
        }

        if (modifiedAfter) {
            queryParams += `modified=${encodeURIComponent(formatISO(modifiedAfter))}&`;
        }

        return this.httpService.makeGet<OrderDto[]>(`${this.endpoint}${queryParams}`);
    }

    public getOrderDetails(
        environment: EnvironmentType,
        kindOfOrder: KindOfOrder,
        orderId: number
    ): Observable<OrderDto> {
        return this.httpService.makeGet<OrderDto>(
            `${this.endpoint}/${environment}/${kindOfOrder.valueOf()}/${orderId}`
        );
    }

    public saveOrderDate$(
        environment: EnvironmentType,
        kindOfOrder: KindOfOrder,
        orderId: number,
        dateDetails: AppointmentSaveDateDto
    ): Observable<Partial<OrderDto>> {
        return this.httpService.makePatch(
            `${this.endpoint}/${environment}/${kindOfOrder.valueOf()}/${orderId}/date`,
            dateDetails
        );
    }
}
