import { Injectable, NgZone } from '@angular/core';
import { ConnectionStatus } from '@capacitor/network';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConnectionType } from '../enums/connection.type';
import { ConnectivityState } from './connectivity-state.model';
import { DynatraceLoggerService } from 'src/app/domain/sync/services/dynatrace-logger.service';
import { UserStorageService } from '@domain/auth';
import { HttpService } from '@shared/http';

@Injectable()
export class ConnectivityStateService {
    private connectivitySubject: BehaviorSubject<ConnectivityState> = new BehaviorSubject(
        new ConnectivityState('unknown')
    );

    public connectivity$ = this.connectivitySubject.asObservable();

    public constructor(
        private ngZone: NgZone,
        private traceService: DynatraceLoggerService,
        private userStorageService: UserStorageService,
        private httpService: HttpService
    ) {}

    public changeWifiConnectivity(status: ConnectionStatus): void {
        const newType = this.getConnectionType(status);
        const newConnectivity = new ConnectivityState(newType);

        if (newConnectivity.connectionType === this.connectivitySubject.value.connectionType) {
            return;
        }

        this.ngZone.run(() => {
            this.connectivitySubject.next(newConnectivity);
            this.traceService.log(
                'INFO',
                `User ${this.userStorageService.getUsername()} network type switched to ${status.connectionType}`,
                'Gimbil'
            );
        });
    }

    public getConnectivityStatusValue(): boolean | undefined {
        return this.connectivitySubject.value.isConnected;
    }

    private getConnectionType(status: ConnectionStatus): ConnectionType {
        const { connected, connectionType } = status;

        if (connected) {
            if (connectionType === 'wifi') {
                return 'wifi';
            } else {
                // on some devices it does not return 'cellular', but '3g' or '4g'
                // so we assume that if it is not wifi then is 'cellular'
                return 'cellular';
            }
        }

        if (connectionType === 'none') {
            return 'none';
        }

        return 'unknown';
    }
}
