import { Injectable } from '@angular/core';
import { TokenProvider } from '@shared/token-interceptor';
import { AuthenticationResult, AuthenticationResultDto } from '@shared/token-interceptor';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthenticationTokenProvider extends TokenProvider {
    private AUTH_KEY = 'AUTH_KEY';

    private tokenSubject = new BehaviorSubject<AuthenticationResultDto>(this.getToken());

    public tokenChanged$ = this.tokenSubject.asObservable();

    constructor(private authenticationResult: AuthenticationResult) {
        super();
    }

    public removeToken(): void {
        localStorage.removeItem(this.AUTH_KEY);
        this.tokenSubject.next(null);
    }

    public getToken(): AuthenticationResult {
        return JSON.parse(localStorage.getItem(this.AUTH_KEY));
    }

    public saveToken(result: AuthenticationResult) {
        this.authenticationResult.setTokenAndRefreshToken(result.token, result.refreshToken);
        const authenticationResultData = this.authenticationResult.getData();
        localStorage.setItem(this.AUTH_KEY, JSON.stringify(authenticationResultData));
        this.tokenSubject.next(authenticationResultData);
    }
}
