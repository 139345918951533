import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppointmentCustomerDTO } from '@domain/database';

@Injectable()
export class IntentService {
    constructor(private platform: Platform) {}

    public openMap(customerDetails: AppointmentCustomerDTO) {
        const customerAddress: string = this.prepareCustomerAddress(customerDetails);

        if (this.platform.is('ios')) {
            window.open('maps://?q=' + customerAddress, '_system');
        } else {
            window.open(`geo:0,0?q=${customerAddress}(${encodeURI(customerAddress)})`, '_system');
        }
    }

    public openPhoneCalls(phoneNumber: string): void {
        window.open(`tel:${phoneNumber}`, '_system');
    }

    public openTexting(phoneNumber: string): void {
        window.open(`sms:${phoneNumber}`, '_system');
    }

    private prepareCustomerAddress(customerDetails: AppointmentCustomerDTO): string {
        let address = customerDetails.address1;

        if (customerDetails.address2) {
            address += ', ' + customerDetails.address2;
        }

        if (customerDetails.city) {
            address += ', ' + customerDetails.city;
        }

        if (customerDetails.state) {
            address += ', ' + customerDetails.state;
        }

        if (customerDetails.zip) {
            address += ', ' + customerDetails.zip;
        }

        if (customerDetails.countryCode) {
            address += ', ' + customerDetails.countryCode;
        }

        return address;
    }
}
