import { EnvironmentType } from '@domain/database';

export class WocAgreementDto {
    public customerName: string;
    public contractorName: string;
    public contractorSignature: string;
    public date: string;
    public signature: string;
    public issueDescription: string;
    public actionsTakenDescription: string;
    public environment: EnvironmentType;
    public workOrderId: number;

    constructor(fields: Partial<WocAgreementDto>) {
        this.customerName = fields.customerName;
        this.contractorName = fields.contractorName;
        this.contractorSignature = fields.contractorSignature;
        this.date = fields.date;
        this.signature = fields.signature;
        this.issueDescription = fields.issueDescription;
        this.actionsTakenDescription = fields.actionsTakenDescription;
        this.environment = fields.environment;
        this.workOrderId = fields.workOrderId;
    }
}
