import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DynatraceRumService {
    private baseUrl = 'https://znr05085.live.dynatrace.com';
    private gimbilID = 'APPLICATION-92B0B6675F59BD02';
    private headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization:
            'API-Token dt0c01.L3H747AFWN5MDW3RDEKTI5CJ.DRRYSBRXORGHXRTGKCTEFBQWAMUWITNT4X3KRZJIX5P5COP3RCWWQWGH3H6ZFCU6',
    });

    constructor(private http: HttpClient) {}

    public useGetDynatraceRumPromise() {
        return this.http
            .get(`${this.baseUrl}/api/v1/rum/jsTagComplete/${this.gimbilID}`, {
                headers: this.headers,
                responseType: 'text',
            })
            .toPromise();
    }
}
