import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PostTemplateChecklistDto } from '../models/post-template-checklist.dto';

@Injectable()
export class PostTemplateChecklistService {
    private urlPath = 'assets/json/aoc_agreement.json';

    constructor(private http: HttpClient) {}

    public getPostTemplateChecklist$(): Observable<PostTemplateChecklistDto> {
        return this.http.get<PostTemplateChecklistDto>(this.urlPath);
    }
}
