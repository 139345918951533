import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { DatabaseProvider } from '../database.provider';
import { AppointmentActionRequired, AppointmentsSearchParams, OrderAppointment } from '../models';
import { SearchOrdersAppointmentsQueryBuilder } from './builders';
import { map, switchMap } from 'rxjs/operators';
import { RxCollection } from 'rxdb';

@Injectable()
export class OrderAppointmentsRepository {
    constructor(private dbProvider: DatabaseProvider) {}

    public getAllOrderAppointments$(): Observable<OrderAppointment[]> {
        const inMemoryOrderAppointments$ = this.getInMemoryOrderAppointments$();
        return inMemoryOrderAppointments$.pipe(switchMap((dbOrderAppointments) => dbOrderAppointments.find().$));
    }

    public getAll() {
        const inMemoryOrderAppointments$ = this.getInMemoryOrderAppointments$();
        return inMemoryOrderAppointments$.pipe(switchMap((dbOrderAppointments) => dbOrderAppointments.find().exec()));
    }

    public async updateOrderAppointments(orderId: string, appointments: Partial<OrderAppointment[]>) {
        const inMemoryOrderAppointments = await this.getInMemoryOrderAppointments();

        // wait until each operation is completed on RxDB, otherwise when trying to insert appointments a conflict error is returned
        await inMemoryOrderAppointments.find().where('orderId').eq(orderId).remove();
        await inMemoryOrderAppointments.awaitPersistence();

        await inMemoryOrderAppointments.bulkInsert(appointments);
        await inMemoryOrderAppointments.awaitPersistence();
    }

    public async updateOrderAppointmentsActionsRequired(orderId: string, actionsRequired: AppointmentActionRequired[]) {
        const inMemoryOrderAppointments = await this.getInMemoryOrderAppointments();
        return await inMemoryOrderAppointments.find().where('orderId').eq(orderId).update({
            $set: {
                actionsRequired,
            },
        });
    }

    public getById$(id: string): Observable<OrderAppointment> | null {
        const inMemoryOrderAppointments$ = this.getInMemoryOrderAppointments$();
        return inMemoryOrderAppointments$.pipe(
            switchMap((orderAppointments) =>
                orderAppointments.findOne(id).$.pipe(
                    map((rxOrderAppointment) => {
                        if (rxOrderAppointment) {
                            return rxOrderAppointment.toJSON();
                        }
                        return null;
                    })
                )
            )
        );
    }

    public async anyOrderAppointments(): Promise<boolean> {
        const inMemoryOrderAppointments = await this.getInMemoryOrderAppointments();
        const orderAppointments = await inMemoryOrderAppointments.find().limit(1).exec();
        return orderAppointments.length > 0;
    }

    public async bulkUpsert(upsertedAppointments: OrderAppointment[]) {
        // unfortunately rxdb does not expose yet a method to Bulk Update, only Bulk Insert
        // which is causing conflicts if items already exists.
        // this is why we implement it ourself for now.

        const inMemoryOrderAppointments = await this.getInMemoryOrderAppointments();
        await Promise.all(upsertedAppointments.map((item) => inMemoryOrderAppointments.atomicUpsert(item)));

        console.log('Order appointments', upsertedAppointments.length);

        await this.dbProvider.get().requestIdlePromise();
    }

    public searchOrdersAppointments$(searchParams: AppointmentsSearchParams): Observable<OrderAppointment[]> {
        const queryModel = new SearchOrdersAppointmentsQueryBuilder(searchParams).build();
        const inMemoryOrderAppointments$ = this.getInMemoryOrderAppointments$();
        return inMemoryOrderAppointments$.pipe(
            switchMap((orderAppointments) => orderAppointments.find(queryModel.query).sort(queryModel.sort).$)
        );
    }

    public searchOrdersAppointmentsCount$(searchParams: AppointmentsSearchParams): Observable<number> {
        return this.searchOrdersAppointments$(searchParams).pipe(map((orders: OrderAppointment[]) => orders.length));
    }

    private async getInMemoryOrderAppointments(): Promise<RxCollection<OrderAppointment, {}, { [key: string]: any }>> {
        return await this.dbProvider.get().orderAppointments?.inMemory();
    }

    private getInMemoryOrderAppointments$(): Observable<RxCollection<OrderAppointment, {}, { [key: string]: any }>> {
        return from(this.dbProvider.get().orderAppointments?.inMemory());
    }
}
