import { DeviceIdService } from '../../../domain/auth/services/deviceid.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticationResult {
    public token: string;
    public refreshToken: string;
    public deviceId: string;
    public otpRequired: boolean;

    public constructor(private deviceIdService: DeviceIdService) {
        this.otpRequired = true;
        this.deviceIdService.getDeviceId().subscribe((deviceId: string) => {
            this.deviceId = deviceId;
        });
    }

    public setTokenAndRefreshToken(token: string, refreshToken: string): void {
        this.token = token;
        this.refreshToken = refreshToken;
    }

    public getData(): AuthenticationResultDto {
        return {
            token: this.token,
            refreshToken: this.refreshToken,
            deviceId: this.deviceId,
            otpRequired: this.otpRequired,
        };
    }
}

export interface AuthenticationResultDto {
    token: string;
    refreshToken: string;
    deviceId: string;
    otpRequired: boolean;
}
