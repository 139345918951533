import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InstallChecklistDto } from '../models/install-checklist.dto';

@Injectable()
export class InstallChecklistService {
    private urlPath = 'assets/json/aoc_agreement.json';

    constructor(private http: HttpClient) {}

    public getInstallChecklist$(): Observable<InstallChecklistDto> {
        return this.http.get<InstallChecklistDto>(this.urlPath);
    }
}
