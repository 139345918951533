import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { CocAgreementDto } from './coc-agreement.dto';
import { Observable } from 'rxjs';

@Injectable()
export class AppointmentCocApi {
    private appointmentsEndpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public submitCocAgreement(
        environment: string,
        retailerOrderId: number,
        cocAgreement: CocAgreementDto
    ): Observable<any> {
        return this.httpService.makePost(
            `${this.appointmentsEndpoint}/${environment}/${retailerOrderId}/aoc`,
            cocAgreement
        );
    }
}
