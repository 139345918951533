import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { WocAgreementDto } from './woc-agreement.dto';
import { Observable } from 'rxjs';
import { OrderDto } from '../models';

@Injectable()
export class AppointmentWocApi {
    private appointmentsEndpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public submitWocAgreement$(
        environment: string,
        retailerOrderId: number,
        wocAgreement: WocAgreementDto
    ): Observable<Partial<OrderDto>> {
        return this.httpService.makePost(
            `${this.appointmentsEndpoint}/${environment}/${retailerOrderId}/woc`,
            wocAgreement
        );
    }
}
