import { Injectable } from '@angular/core';
import { OrdersSyncService, RetailerSyncService, SyncService } from '../services';

@Injectable()
export class SyncConfigurationService {
    constructor(
        private syncService: SyncService,
        private retailSyncService: RetailerSyncService,
        private ordersSyncService: OrdersSyncService
    ) {}

    public configureSync() {
        this.syncService.addSyncService(this.ordersSyncService);
        this.syncService.addSyncService(this.retailSyncService);
    }
}
