import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { AocAgreementDto } from './aoc-agreement.dto';
import { Observable } from 'rxjs';
import { OrderDto } from '../models';

@Injectable()
export class AppointmentAocApi {
    private appointmentsEndpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public submitAocAgreement$(
        environment: string,
        retailerOrderId: number,
        aocAgreement: AocAgreementDto
    ): Observable<Partial<OrderDto>> {
        return this.httpService.makePost(
            `${this.appointmentsEndpoint}/${environment}/${retailerOrderId}/aoc`,
            aocAgreement
        );
    }
}
