import { QueryExtender } from '../query.extender';
import { MangoQuerySortDirection } from 'rxdb';
import { AppointmentDateTypes, AppointmentStatus } from '../../models';
import { getUnixTime, startOfTomorrow } from 'date-fns';
import { QueryModel } from '../query-extender.model';

export class UpcomingDateQueryExtender implements QueryExtender {
    private dateType: AppointmentDateTypes | null;
    private tomorrowUnixTimestamp: number;

    constructor(dateType: AppointmentDateTypes | null) {
        this.dateType = dateType;
        this.tomorrowUnixTimestamp = getUnixTime(startOfTomorrow()) - 1;
    }

    public extend(finalQuery: QueryModel) {
        if (Number(this.dateType) === AppointmentDateTypes.Upcoming) {
            const rangeCondition = {
                status: { $in: [AppointmentStatus.Open] },
                filterableDate: { $gt: this.tomorrowUnixTimestamp },
            };

            finalQuery.query.selector.$and.push(rangeCondition);
            finalQuery.sort.filterableDate = 'asc' as MangoQuerySortDirection;
        }

        return finalQuery;
    }
}
