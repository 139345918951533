import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Device } from '@ionic-native/device/ngx';

@Injectable()
export class DeviceIdService {
    constructor(private device: Device) {}

    // Returns unique device id persistent during app reinstall only. Factory reset renews the id.
    public getDeviceId(): Observable<string> {
        return this.device.uuid ? of(this.device.uuid) : of('unknownDeviceID');
    }
}
