import { Injectable } from '@angular/core';
import { HttpService } from '@shared/http';
import { formatISO } from 'date-fns';
import { Observable } from 'rxjs';
import { ProductDto } from '../models/product.dto';

@Injectable()
export class ProductService {
    private endpoint = 'api/products';

    constructor(private httpService: HttpService) {}

    public getAllProducts(modifiedAfter?: Date): Observable<ProductDto[]> {
        const queryParams = modifiedAfter ? `?modified=${encodeURIComponent(formatISO(modifiedAfter))}` : '';
        return this.httpService.makeGet<ProductDto[]>(`${this.endpoint}${queryParams}`);
    }
}
