import { Injectable } from '@angular/core';
import { partition, uniq } from 'lodash';
import { RxDatabase, RxDocument } from 'rxdb';
import { Observable, of } from 'rxjs';
import { DatabaseProvider } from '../database.provider';
import { GimbilDbContext } from '../models/gimbil.db-context';
import { Retailer } from '../models/retailer.model';

@Injectable()
export class RetailersRepository {
    private db: RxDatabase<GimbilDbContext>;

    constructor(private dbProvider: DatabaseProvider) {
        this.db = this.dbProvider.get();
    }

    public getAll$() {
        return this.dbProvider.get().retailers.find().$;
    }

    public getAll() {
        return this.dbProvider.get().retailers.find().exec();
    }

    public async getMaxModifiedUnix() {
        const items = await this.dbProvider
            .get()
            .retailers.find()
            .sort({
                modifiedUnix: 'desc',
            })
            .limit(1)
            .exec();

        if (items.length === 0) {
            return undefined;
        }

        return items[0].modifiedUnix;
    }

    public async bulkUpsert(retailers: Retailer[]) {
        const hasAny = (await this.dbProvider.get().retailers.find().limit(1).exec()).length > 0;
        console.log('Retailers', retailers.length);
        if (!hasAny) {
            await this.initialImport(retailers);
        } else {
            await this.updateBatch(retailers);
        }
    }

    public getByIds$(retailerIds: string[]): Observable<RxDocument<Retailer>[]> {
        return this.dbProvider.get().retailers.find().where('id').in(retailerIds).$;
    }

    public getById$(id: string): Observable<Retailer> {
        return this.dbProvider.get().retailers.findOne(id).$;
    }

    public getByRetailerCodes$(retailerCodes: string[]): Observable<RxDocument<Retailer>[]> {
        if (!retailerCodes) {
            return of<RxDocument<Retailer>[]>([]);
        }

        return this.dbProvider.get().retailers.find().where('retailerCode').in(retailerCodes).$;
    }

    private async updateBatch(upsertedRetailers: Retailer[]) {
        const retailers = this.dbProvider.get().retailers;
        await Promise.all(upsertedRetailers.map((item) => retailers.atomicUpsert(item)));
        await this.dbProvider.get().requestIdlePromise();
    }

    private async initialImport(retailers: Retailer[]) {
        // the API returns all retailers, which are a lot
        // so we want to optimize persistance and store them in two phases
        // 1. store the Retailers required by existing jobs
        // 2. store the rest of them in the background with a delay
        // instead of manually using find().exec() we should use map reduce from pouchdb using query func
        // Review this part in detail. The pouchdb documentation does not recommend using map / reduce to query for optimization reasons.
        // we still need to review this 2 part storing system.
        const allOrders = await this.dbProvider.get().orders.find().exec();
        const allRetailerIds = allOrders.map((j) => j.dealerId);
        const uniqIds = uniq(allRetailerIds);
        const [insertNow, insertLater] = partition(retailers, (r) => uniqIds.indexOf(r.id) >= 0);
        await this.dbProvider.get().retailers.bulkInsert(insertNow);
        await this.dbProvider.get().requestIdlePromise();

        // tslint:disable-next-line: no-console
        //console.time('Delayed retailer import');
        await this.dbProvider.get().retailers.bulkInsert(insertLater);
        await this.dbProvider.get().requestIdlePromise();
        // tslint:disable-next-line: no-console
    }
}
