import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CieAgreementDto } from '../models/cie-agreement.dto';

@Injectable()
export class CieAgreementService {
    private urlPath = 'assets/json/cie_agreement.json';

    constructor(private http: HttpClient) {}

    public getCieAgreement$(): Observable<CieAgreementDto> {
        return this.http.get<CieAgreementDto>(this.urlPath);
    }
}
