import { QueryExtender } from '../query.extender';
import { AppointmentDateTypes, AppointmentStatus } from '../../models';
import { QueryModel } from '../query-extender.model';

export class DoneDateQueryExtender implements QueryExtender {
    private dateType: AppointmentDateTypes | null;

    constructor(dateType: AppointmentDateTypes | null) {
        this.dateType = dateType;
    }

    public extend(finalQuery: QueryModel): QueryModel {
        if (Number(this.dateType) === AppointmentDateTypes.Done) {
            const rangeCondition = {
                status: { $in: [AppointmentStatus.Completed] },
            };

            finalQuery.query.selector.$and.push(rangeCondition);
        }

        return finalQuery;
    }
}
