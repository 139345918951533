import { AppointmentDate, AppointmentType } from '@domain/database';
import { AppointmentDateTypes } from '../../models';

export abstract class AppointmentDateBaseHandler implements AppointmentDateBaseHandler {
    private nextHandler: AppointmentDateBaseHandler;

    public setNext(handler: AppointmentDateBaseHandler): AppointmentDateBaseHandler {
        this.nextHandler = handler;
        return handler;
    }

    public handle(
        appointmentType: AppointmentType,
        appointmentDates: AppointmentDate[],
        dateType: AppointmentDateTypes
    ): number | null {
        if (this.nextHandler) {
            return this.nextHandler.handle(appointmentType, appointmentDates, dateType);
        }

        return null;
    }
}
