import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { DatabaseProvider } from '../database.provider';
import { AppointmentCustomerDTO, AppointmentsSearchParams, Order } from '../models';
import { SearchOrdersQueryBuilder } from './builders';
import { map, switchMap } from 'rxjs/operators';
import { RxCollection } from 'rxdb';
import { uniq, uniqBy } from 'lodash';
import { RetailersRepository } from './retailers.repository';
import { RetailerModel } from '@domain/appointment';

@Injectable()
export class OrdersRepository {
    constructor(private dbProvider: DatabaseProvider, private retailerRepo: RetailersRepository) {}

    public getAllRetailersFromOrders$(): Observable<RetailerModel[]> {
        const orders$ = this.getInMemoryOrders$();
        return orders$.pipe(
            switchMap((dbOrders) => dbOrders.find().$),
            // Map all the orders to co-responding retailers
            switchMap((orders) => {
                const retailerIds = uniq(orders.map((x) => x.dealerId));
                return this.retailerRepo.getByIds$(retailerIds);
            }),
            // map to unique retailers with correct interface
            map((retailers) =>
                uniqBy(
                    retailers.map((retailer) => {
                        return {
                            id: retailer.id,
                            retailerCode: retailer.retailerCode,
                        };
                    }),
                    'retailerCode'
                )
            )
        );
    }

    public async updateOrderDocuments(orderId: string, order: Partial<Order>): Promise<Order[]> {
        const inMemoryOrders = await this.getInMemoryOrders();
        return await inMemoryOrders
            .find()
            .where('id')
            .eq(orderId)
            .update({
                $set: {
                    modifiedUnix: order.modifiedUnix,
                    documents: order.documents,
                    actionsRequired: order.actionsRequired,
                },
            });
    }

    // TODO: Verify that this is working, changed from Promise<Order[]> to <void>
    public async updateOrderNotes(orderId: string, order: Partial<Order>): Promise<void> {
        const inMemoryOrders = await this.getInMemoryOrders();
        await inMemoryOrders
            .find()
            .where('id')
            .eq(orderId)
            .update({
                $set: {
                    modifiedUnix: order.modifiedUnix,
                    notes: order.notes,
                    isChangeNoteAllowed: order.isChangeNoteAllowed,
                },
            });
        inMemoryOrders.awaitPersistence();
    }

    public getAll() {
        const orders$ = this.getInMemoryOrders$();
        return orders$.pipe(switchMap((dbOrders) => dbOrders.find().exec()));
    }

    public async getMaxModifiedUnix() {
        const inMemoryOrders = await this.getInMemoryOrders();
        const items = await inMemoryOrders
            .find()
            .sort({
                modifiedUnix: 'desc',
            })
            .limit(1)
            .exec();

        if (items.length === 0) {
            return null;
        }

        return items[0].modifiedUnix;
    }

    public getById$(id: string): Observable<Order> {
        const inMemoryOrders$ = this.getInMemoryOrders$();
        return inMemoryOrders$.pipe(
            switchMap((order) => order.findOne(id).$.pipe(map((rxOrder) => rxOrder?.toJSON())))
        );
    }

    // AppointmentCustomerDetails should be move into database section
    // or move orders repository into appointment domain
    public getCustomerInfoForOrder$(id: string): Observable<AppointmentCustomerDTO> {
        const inMemoryOrders$ = this.getInMemoryOrders$();
        return inMemoryOrders$.pipe(switchMap((orders) => orders.findOne(id).$.pipe(map((order) => order.customer))));
    }

    public async anyOrders(): Promise<boolean> {
        const inMemoryOrders = await this.getInMemoryOrders();
        const orders = await inMemoryOrders.find().limit(1).exec();
        return orders.length > 0;
    }

    public async bulkUpsert(upsertedOrders: Order[]) {
        // unfortunatelly rxdb does not explose yet a method to Bulk Update, only Bulk Insert
        // which is causing conflicts if items already exists.
        // this is why we implement it ourselfs for now.

        const inMemoryOrders = await this.getInMemoryOrders();
        await Promise.all(upsertedOrders.map((item) => inMemoryOrders.atomicUpsert(item)));
        inMemoryOrders.awaitPersistence();

        console.log('Orders', upsertedOrders.length);

        await this.dbProvider.get().requestIdlePromise();
    }

    public searchOrders$(searchParams: AppointmentsSearchParams): Observable<Order[]> {
        const queryModel = new SearchOrdersQueryBuilder(searchParams).build();
        const inMemoryOrders$ = this.getInMemoryOrders$();
        return inMemoryOrders$.pipe(switchMap((orders) => orders.find(queryModel.query).sort(queryModel.sort).$));
    }

    public searchOrdersCount$(searchParams: AppointmentsSearchParams): Observable<number> {
        return this.searchOrders$(searchParams).pipe(map((orders: Order[]) => orders.length));
    }

    private async getInMemoryOrders(): Promise<RxCollection<Order, {}, { [key: string]: any }>> {
        return await this.dbProvider.get().orders?.inMemory();
    }

    private getInMemoryOrders$(): Observable<RxCollection<Order, {}, { [key: string]: any }>> {
        return from(this.dbProvider.get().orders?.inMemory());
    }
}
