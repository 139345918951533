import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { Observable } from 'rxjs';
import { OrderNoteDto } from './order-notes.dto';
import { EnvironmentType, KindOfOrder } from '@domain/database';

@Injectable()
export class OrderNotesApi {
    private appointmentsEndpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public saveOrderNote(
        environment: EnvironmentType,
        kindOfOrder: KindOfOrder,
        orderId: number,
        orderNote: OrderNoteDto
    ): Observable<any> {
        return this.httpService.makePost(
            `${this.appointmentsEndpoint}/${environment}/${kindOfOrder.valueOf()}/${orderId}/note`,
            orderNote
        );
    }
}
