import { Injectable, OnDestroy } from '@angular/core';
import { AuthService, UserStorageService } from '@domain/auth';
import { ConnectionType, ConnectivityStateService } from '@shared/network-connectivity';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { SyncService } from './sync.service';
import { DynatraceLoggerService } from './dynatrace-logger.service';

@Injectable()
export class AutoSyncService implements OnDestroy {
    private subscription?: Subscription;

    constructor(
        private syncService: SyncService,
        private authService: AuthService,
        private networkStatus: ConnectivityStateService,
        private traceService: DynatraceLoggerService,
        private userStorageService: UserStorageService
    ) {}

    /**
     * Automatically triggers the sync mechanism. In case that it was already started it will reconfigure using new params
     * @param refreshInterval in milliseconds
     * @param networks connection types to autorefresh
     */
    public startAutoSync(refreshInterval: number, connectionTypes: ConnectionType[]) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = combineLatest([
            this.syncService.isSyncing$,
            this.authService.isAuthenticated$,
            this.networkStatus.connectivity$,
        ])
            .pipe(
                debounceTime(refreshInterval),
                filter(([isSyncing, isAuthenticated, connection]) => {
                    return !isSyncing && isAuthenticated && connectionTypes.indexOf(connection.connectionType) >= 0;
                })
            )
            .subscribe(([isSyncing, isAuthenticated, connectionUsed]) => {
                this.traceService.log(
                    'INFO',
                    `User ${this.userStorageService.getUsername()} Auto-Syncing triggered on ${
                        connectionUsed.connectionType
                    }`,
                    'Gimbil'
                );
                this.syncService.startSync();
            });
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
