import { EnvironmentType } from '@domain/database';

export class InstallChecklistDto {
    public customerName: string;
    public contractorName: string;
    public contractorSignature: string;
    public installBrand: string;
    public installOptions: string;
    public installQty: string;
    public jobSiteNotes: string;
    public jobSiteOptions: string;
    public jobSiteBroomed: boolean;
    public customerReviewOptions: string;
    public installerIssues: boolean;
    public installerIssuesNo: boolean;
    public installerIssuesYes: boolean;
    public installerIssuesPictures: boolean;
    public installerDamages: string;
    public date: string;
    public signature: string;
    public environment: EnvironmentType;
    public retailOrderId: number;

    constructor(fields: Partial<InstallChecklistDto>) {
        this.customerName = fields.customerName;
        this.contractorName = fields.contractorName;
        this.contractorSignature = fields.contractorSignature;
        this.installBrand = fields.installBrand;
        this.installOptions = fields.installOptions;
        this.installQty = fields.installQty;
        this.jobSiteBroomed = fields.jobSiteBroomed;
        this.jobSiteOptions = fields.jobSiteOptions;
        this.jobSiteNotes = fields.jobSiteNotes;
        this.customerReviewOptions = fields.customerReviewOptions;
        this.installerIssues = fields.installerIssues;
        this.installerIssuesNo = fields.installerIssuesNo;
        this.installerIssuesYes = fields.installerIssuesYes;
        this.installerIssuesPictures = fields.installerIssuesPictures;
        this.installerDamages = fields.installerDamages;
        this.date = fields.date;
        this.signature = fields.signature;
        this.environment = fields.environment;
        this.retailOrderId = fields.retailOrderId;
    }
}
