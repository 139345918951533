import { Injectable } from '@angular/core';
import { HttpService } from '@shared/http';
import { AuthenticationResult } from '@shared/token-interceptor';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OtpModel } from '../models';
import { AuthenticationTokenProvider } from '../providers';

@Injectable()
export class OtpService {
    private authenticateEndpoint = 'auth';

    constructor(private httpService: HttpService, private tokenProvider: AuthenticationTokenProvider) {}

    public isAuthenticated(): boolean {
        const authResult: AuthenticationResult = this.tokenProvider.getToken();
        if (!!authResult) {
            return true;
        }

        return false;
    }

    public validateOtp(otp: OtpModel): Observable<AuthenticationResult> {
        return this.httpService.makePost(`${this.authenticateEndpoint}/otp`, otp).pipe(
            tap((authenticationResult: AuthenticationResult) => {
                this.tokenProvider.saveToken(authenticationResult);
            })
        );
    }
}
