import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable()
export class AlertService {
    public alertPresented: boolean;

    constructor(private alertController: AlertController) {
        this.alertPresented = false;
    }

    public async presentAlert(
        header: string,
        message: string,
        buttons: any[],
        isBackDropDismiss?: boolean
    ): Promise<any> {
        if (!this.alertPresented) {
            this.alertPresented = true;

            const alertReference = await this.alertController.create({
                header: header,
                message: message,
                buttons: buttons,
                cssClass: ['custom-alert'],
                backdropDismiss: isBackDropDismiss,
            });
            alertReference.onDidDismiss().then(() => (this.alertPresented = false));

            return await alertReference.present();
        }
    }
}
