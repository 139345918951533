import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SyncConfigurationService } from './configuration';
import { SyncGuard } from './guards';
import { AutoSyncService, DataHelperService, OrdersSyncService, RetailerSyncService, SyncService } from './services';
import { ToastModule } from '@shared/toast';

@NgModule({
    declarations: [],
    imports: [CommonModule, ToastModule],
})
export class DomainSyncModule {
    public static forRoot(): ModuleWithProviders<DomainSyncModule> {
        return {
            ngModule: DomainSyncModule,
            providers: [
                SyncService,
                AutoSyncService,
                SyncGuard,
                SyncConfigurationService,
                RetailerSyncService,
                OrdersSyncService,
                DataHelperService,
            ],
        };
    }
}
