import { QueryExtender } from '../query.extender';
import { QueryModel } from '../query-extender.model';

export class AppointmentTypesQueryExtender implements QueryExtender {
    private jobTypes?: number[] | null;

    constructor(jobTypes: number[] | null) {
        this.jobTypes = jobTypes;
    }

    public extend(finalQuery: QueryModel): QueryModel {
        if (this.jobTypes) {
            const jobTypesCondition = {
                appointmentType: { $in: this.jobTypes },
            };

            finalQuery.query.selector.$and.push(jobTypesCondition);
        }

        return finalQuery;
    }
}
