import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AuthenticationTokenProvider, AuthGuardService, RefreshTokenProvider } from '@domain/auth';
import { DomainCoreModule } from '@domain/core';
import { DomainSyncModule } from '@domain/sync';
import { Network } from '@ionic-native/network/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedHttpModule } from '@shared/http';
import { SharedTokenInterceptorModule } from '@shared/token-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DynatraceRumService } from './domain/sync/services/dynatrace-rum.service';
import { DynatraceLoggerService } from './domain/sync/services/dynatrace-logger.service';
import { SwiperModule } from 'swiper/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
    declarations: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        AppRoutingModule,
        IonicModule.forRoot(),
        SharedHttpModule.forRoot(),
        DomainCoreModule,
        HttpClientModule,
        SwiperModule,
        ScrollingModule,
        SharedTokenInterceptorModule.forRoot({
            tokenProvider: AuthenticationTokenProvider,
            refreshTokenProvider: RefreshTokenProvider,
        }),
        DomainSyncModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http) => new TranslateHttpLoader(http),
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Network,
        DynatraceRumService,
        DynatraceLoggerService,
        AuthGuardService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
