import { EnvironmentType } from '@domain/database';

export class PackingSlipDto {
    public customerName: string;
    public signature: string;
    public environment: EnvironmentType;
    public retailOrderId: number;
    public date: string;

    constructor(fields: Partial<PackingSlipDto>) {
        this.customerName = fields.customerName;
        this.signature = fields.signature;
        this.environment = fields.environment;
        this.retailOrderId = fields.retailOrderId;
        this.date = fields.date;
    }
}
