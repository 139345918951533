import { QueryExtender } from '../query.extender';
import { QueryModel } from '../query-extender.model';

export class RetailersQueryExtender implements QueryExtender {
    private retailers?: string[] | null;

    constructor(retailers: string[] | null) {
        this.retailers = retailers;
    }

    public extend(finalQuery: QueryModel): QueryModel {
        if (this.retailers) {
            const retailersCondition = {
                dealerId: { $in: this.retailers },
            };
            finalQuery.query.selector.$and.push(retailersCondition);
        }

        return finalQuery;
    }
}
