import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { Observable } from 'rxjs';
import { AppointmentDocumentType, OrderDto } from '../models';
import { EnvironmentType, KindOfOrder } from '@domain/database';

@Injectable()
export class ImageService {
    private appointmentsEndpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public uploadPhotos$(
        environment: EnvironmentType,
        kindOfOrder: KindOfOrder,
        orderId: number,
        documentType: AppointmentDocumentType,
        photosData: (string | Blob)[]
    ): Observable<Partial<OrderDto>> {
        return this.httpService.makePost(
            `${this.appointmentsEndpoint}/${environment}/${kindOfOrder.valueOf()}/${orderId}/photos`,
            {
                data: photosData,
                documentType,
            }
        );
    }

    public deletePhoto$(
        environment: EnvironmentType,
        kindOfOrder: KindOfOrder,
        orderId: number,
        documentId: number
    ): Observable<Partial<OrderDto>> {
        return this.httpService.makeDelete(
            `${this.appointmentsEndpoint}/${environment}/${kindOfOrder.valueOf()}/${orderId}/photos/${documentId}`
        );
    }
}
