import { EnvironmentType } from '@domain/database';

export class CieAgreementDto {
    public customerName: string;
    public isRequest: boolean;
    public isWaive: boolean;
    public companyCode: string;
    public customerEmail: string;
    public customerPhone: string;
    public customerState: string;
    public retailerName: string;
    public date: string;
    public signature: string;
    public environment: EnvironmentType;
    public retailOrderId: number;

    constructor(fields: Partial<CieAgreementDto>) {
        this.customerName = fields.customerName;
        this.isRequest = fields.isRequest;
        this.isWaive = fields.isWaive;
        this.companyCode = fields.companyCode;
        this.customerEmail = fields.customerEmail;
        this.customerPhone = fields.customerPhone;
        this.customerState = fields.customerState;
        this.retailerName = fields.retailerName;
        this.date = fields.date;
        this.signature = fields.signature;
        this.environment = fields.environment;
        this.retailOrderId = fields.retailOrderId;
    }
}
