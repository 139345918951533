import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, Observable, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AlertService } from '@shared/alert';
import { AuthService } from '@domain/auth';
import { HttpService } from '../services';
import { ErrorModel } from '../models';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
    private maintenanceEndpoint = 'auth/isOnMaintenance';
    public isOnMaintenance: boolean = false;

    constructor(
        private httpService: HttpService,
        private translateService: TranslateService,
        private alertService: AlertService,
        private authService: AuthService
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.indexOf('/assets/i18n/') > -1 || request.url.indexOf('auth/isOnMaintenance') > -1)
            return next.handle(request);

        if (this.isOnMaintenance) return;

        return this.getIsOnMaintenance().pipe(
            switchMap((result: boolean) => {
                this.isOnMaintenance = result;

                if (result) {
                    throw 'isOnMaintenance';
                }

                return next.handle(request);
            }),
            catchError(async (e: ErrorModel) => {
                if (this.isOnMaintenance) {
                    this.showOfflineForMaintenanceMessage();
                }

                throw e;
            })
        );
    }

    private getIsOnMaintenance(): Observable<boolean> {
        return this.httpService.makeGet<boolean>(this.maintenanceEndpoint);
    }

    private async showOfflineForMaintenanceMessage() {
        const okayButton = {
            text: this.translateService.instant('networkStatusBar.buttons.ok.label'),
            handler: async () => {
                this.isOnMaintenance = false;
                this.authService.logout(true);
            },
        };

        await this.alertService.presentAlert(
            '',
            this.translateService.instant('networkStatusBar.alerts.isOfflineForMaintenance'),
            [okayButton],
            false
        );
    }
}
