import { ModuleWithProviders, NgModule } from '@angular/core';
import { ProductService } from './services';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [],
})
export class DomainProductModule {
    public static forRoot(): ModuleWithProviders<DomainProductModule> {
        return {
            ngModule: DomainProductModule,
            providers: [ProductService],
        };
    }
}
