import { APP_INITIALIZER, NgModule } from '@angular/core';
import { OrderAppointmentsRepository, OrdersRepository, RetailersRepository } from './repositories';
import { DatabaseProvider } from './database.provider';

function AppInitializer(dbProvider: DatabaseProvider) {
    return () => dbProvider.create();
}

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        RetailersRepository,
        OrdersRepository,
        OrderAppointmentsRepository,
        {
            provide: APP_INITIALIZER,
            useFactory: AppInitializer,
            deps: [DatabaseProvider],
            multi: true,
        },
        DatabaseProvider,
    ],
    bootstrap: [],
})
export class DatabaseModule {}
