import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { Observable } from 'rxjs';
import { OrderDto } from '../models';
import { PackingSlipDto } from './packing-slip.dto';

@Injectable()
export class PackingSlipApi {
    private appointmentsEndpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public submitPackingSlipAgreement$(
        environment: string,
        retailerOrderId: number,
        packingSlip: PackingSlipDto
    ): Observable<Partial<OrderDto>> {
        return this.httpService.makePost(
            `${this.appointmentsEndpoint}/${environment}/${retailerOrderId}/ps`,
            packingSlip
        );
    }
}
