import { MangoQuerySortDirection } from 'rxdb';
import { AppointmentDateTypes, AppointmentsSearchParams } from '../../models';
import {
    AlertsDateQueryExtender,
    AppointmentTypesQueryExtender,
    DoneDateQueryExtender,
    KeywordsQueryExtender,
    QueryExtender,
    QueryModel,
    RetailersQueryExtender,
    TodayDateQueryExtender,
    UpcomingDateQueryExtender,
} from '../../queries';

export class SearchOrdersAppointmentsQueryBuilder {
    private readonly dateTypes?: AppointmentDateTypes;
    private readonly keywords?: string;
    private readonly jobTypes?: number[];
    private readonly retailers?: string[];

    constructor(params: Partial<AppointmentsSearchParams>) {
        this.dateTypes = params.dateTypes;
        this.keywords = params.keywords;
        this.jobTypes = params.jobTypes;
        this.retailers = params.retailers;
    }

    private static getInitializedQuery(): QueryModel {
        let queryModel: QueryModel = {
            query: {
                selector: { $and: [{ filterableDate: { $gt: -1000 } }], $or: [] },
            },
            sort: {
                [Symbol.iterator]: 'asc',
            },
        };

        queryModel.sort.filterableDate = 'desc' as MangoQuerySortDirection;

        // this is required because the sort field has to be present in the selector, check documentation.
        // and always true condition is added
        return queryModel;
    }

    public build(): QueryModel {
        let queryModel = SearchOrdersAppointmentsQueryBuilder.getInitializedQuery();
        const extenders: QueryExtender[] = [];
        extenders.push(new KeywordsQueryExtender(this.keywords));
        if (!this.keywords) {
            extenders.push(new AlertsDateQueryExtender(this.dateTypes));
            extenders.push(new TodayDateQueryExtender(this.dateTypes));
            extenders.push(new UpcomingDateQueryExtender(this.dateTypes));
            extenders.push(new DoneDateQueryExtender(this.dateTypes));
        }
        extenders.push(new AppointmentTypesQueryExtender(this.jobTypes));
        extenders.push(new RetailersQueryExtender(this.retailers));

        extenders.forEach((extender) => {
            queryModel = extender.extend(queryModel);
        });

        return queryModel;
    }
}
