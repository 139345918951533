import { EnvironmentType } from '@domain/database';

export class OrderNoteDto {
    public text: string;
    public type: number;
    public environment: EnvironmentType;
    public retailOrderId: number;

    constructor(fields: Partial<OrderNoteDto>) {
        this.text = fields.text;
        this.type = fields.type;
        this.environment = fields.environment;
        this.retailOrderId = fields.retailOrderId;
    }
}
