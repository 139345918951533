import { RxJsonSchema } from 'rxdb';

const schema: RxJsonSchema = {
    title: 'retailer schema',
    description: 'no description',
    version: 0,
    keyCompression: false,
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
        },
        retailerId: { type: 'number' },
        environment: { type: 'string' },
        name: {
            type: 'string',
        },
        retailerCode: {
            type: 'string',
        },
        address1: {
            type: 'string',
        },
        address2: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        zip: {
            type: 'string',
        },
        countryCode: {
            type: 'string',
        },
        phone: {
            type: 'string',
        },
        email: {
            type: 'string',
        },
        modifiedUnix: {
            type: 'number',
        },
        storeNumber: {
            type: 'string',
        },
    },
    required: ['id', 'retailerId', 'environment', 'modifiedUnix'],
    indexes: ['retailerCode', 'modifiedUnix'],
};
export default schema;
