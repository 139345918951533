import { AppointmentDateBaseHandler } from './appointmentDateBase.handler';
import { AppointmentDate, AppointmentType } from '@domain/database';
import { AppointmentDateType, AppointmentDateTypes } from '../../models';

export class AppointmentDatesHandler extends AppointmentDateBaseHandler {
    public handle(
        appointmentType: AppointmentType,
        appointmentDates: AppointmentDate[],
        dateType: AppointmentDateTypes
    ): number | null {
        if (dateType === AppointmentDateTypes.CABINET_DATE) {
            const cabinetDate: AppointmentDate | undefined = appointmentDates.find(
                (x) => x.dateType === AppointmentDateType.CabinetDate
            );

            return cabinetDate ? cabinetDate.dateUnix : null;
        }

        if (dateType === AppointmentDateTypes.CUSTOMER_CONTACT_DATE) {
            const customerContactDate: AppointmentDate | undefined = appointmentDates.find(
                (x) => x.dateType === AppointmentDateType.CustomerContactDate
            );

            return customerContactDate ? customerContactDate.dateUnix : null;
        }

        if (dateType === AppointmentDateTypes.READYT_TO_MEASURE_OR_TEMPLATE_DATE) {
            const readyToMeasureTemplateDate: AppointmentDate | undefined = appointmentDates.find(
                (x) => x.dateType === AppointmentDateType.ReadyToMeasureTemplateDate
            );

            return readyToMeasureTemplateDate ? readyToMeasureTemplateDate.dateUnix : null;
        }

        return super.handle(appointmentType, appointmentDates, dateType);
    }
}
