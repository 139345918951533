import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastService } from './services';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    exports: [],
    providers: [ToastService],
})
export class ToastModule {}
