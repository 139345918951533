import { EnvironmentType } from '@domain/database';

export class AocAgreementDto {
    public customerName: string;
    public contractorName: string;
    public contractorSignature: string;
    public isNoSurplus: boolean;
    public isAcceptedAll: boolean;
    public isDeclineSurplus: boolean;
    public date: string;
    public signature: string;
    public environment: EnvironmentType;
    public retailOrderId: number;

    constructor(fields: Partial<AocAgreementDto>) {
        this.customerName = fields.customerName;
        this.contractorName = fields.contractorName;
        this.contractorSignature = fields.contractorSignature;
        this.isNoSurplus = fields.isNoSurplus;
        this.isAcceptedAll = fields.isAcceptedAll;
        this.isDeclineSurplus = fields.isDeclineSurplus;
        this.date = fields.date;
        this.signature = fields.signature;
        this.environment = fields.environment;
        this.retailOrderId = fields.retailOrderId;
    }
}
