import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastType } from '../models';

@Injectable()
export class ToastService {
    constructor(private toastController: ToastController) {}

    public async presentToast(message: string, duration: number, toastType: number): Promise<any> {
        const toastReference = await this.toastController.create({
            message,
            duration,
            position: 'top',
            animated: true,
            cssClass: toastType === ToastType.Success ? 'toast-success' : 'toast-error',
        });

        return await toastReference.present();
    }
}
