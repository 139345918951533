import { QueryExtender } from '../query.extender';
import { escapeRegExp } from 'lodash';
import { QueryModel } from '../query-extender.model';

export class KeywordsQueryExtender implements QueryExtender {
    private keywords: string | null;

    constructor(keywords: string | null) {
        this.keywords = keywords;
    }

    public extend(finalQuery: QueryModel): QueryModel {
        if (this.keywords) {
            const regexp = new RegExp('.*' + escapeRegExp(this.keywords) + '.*', 'i');

            const keywordQuery = {
                $or: [],
            };
            keywordQuery.$or = [
                { 'customer.name': { $regex: regexp } },
                { dealerId: { $regex: regexp } },
                { roNumber: { $regex: regexp } },
                { 'customer.address1': { $regex: regexp } },
                { 'customer.city': { $regex: regexp } },
                { 'customer.zip': { $regex: regexp } },
            ];

            if (!finalQuery.query.selector.$and) {
                finalQuery.query.selector.$and = [];
            }

            finalQuery.query.selector.$and.push(keywordQuery);

            return finalQuery;
        }

        return finalQuery;
    }
}
