import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SyncBaseService } from './sync-base.service';
import { ToastDuration, ToastService, ToastType } from '@shared/toast';
import { TranslateService } from '@ngx-translate/core';
import { OrdersRepository } from '../../database/repositories';

@Injectable()
export class SyncService {
    private syncServices: Array<SyncBaseService> = new Array<SyncBaseService>();

    private isSyncingSubject = new BehaviorSubject(false);
    public isSyncing$ = this.isSyncingSubject.asObservable();

    private doneSyncs: Array<Observable<boolean>> = new Array<Observable<boolean>>();
    public doneSyncing$ = combineLatest(this.doneSyncs)
        .pipe(map((values) => values.every((v) => v === true)))
        .pipe(debounceTime(100), distinctUntilChanged());

    // improvement idea: should we store this value in localStorage as well or is good enough to keep it in memory
    private lastSyncDateSubject = new BehaviorSubject(new Date());
    public lastSyncDate$ = this.lastSyncDateSubject.asObservable();

    constructor(
        private ordersFacade: OrdersRepository,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {}

    public addSyncService(syncService: SyncBaseService) {
        this.syncServices.push(syncService);
        this.doneSyncs.push(syncService.done$);
    }

    public async startSync(): Promise<void> {
        try {
            // ideally we execute syncs in parallel (at least the API requests).
            // but at this moment the Retailer sync is dependent on the Appointment
            // (because of some optimizations using delayed initial import). Later on, we might
            // improve the performance using calls in parallel

            if (this.isSyncingSubject.value) {
                return;
            }

            this.isSyncingSubject.next(true);

            for (const service of this.syncServices) {
                await service.startSyncing();
            }

            this.lastSyncDateSubject.next(new Date());
        } catch (error) {
            console.error(`error on sync: ${error}`);

            if (error.toString().toLowerCase() !== 'isonmaintenance') {
                this.toastService.presentToast(
                    this.translateService.instant('errorCodes.RXDB_SYNC'),
                    ToastDuration.Slow,
                    ToastType.Error
                );
            }
        } finally {
            this.isSyncingSubject.next(false);
        }
    }

    public async wasInitialSyncDone(): Promise<boolean> {
        return await this.ordersFacade.anyOrders();
    }
}
