import { EnvironmentType } from '@domain/database';

export class PostTemplateChecklistDto {
    public customerName: string;
    public contractorName: string;
    public contractorSignature: string;
    public cabinetryOptions: string;
    public bracketsOptions: string;
    public sinksOptions: string;
    public sinkMake: string;
    public sinkSupplied: boolean;
    public sinkSuppliedCustomer: boolean;
    public sinkSuppliedInstaller: boolean;
    public sinkModel: string;
    public installationOptions: string;
    public faucetOptions: string;
    public faucetOnsite: boolean;
    public onsite: boolean;
    public faucetMake: string;
    public faucetModel: string;
    public holesQty: string;
    public reveal: string;
    public plumbingTypeInstaller: boolean;
    public plumbingTypeCustomer: boolean;
    public plumbingDisconnect: boolean;
    public plumbingReconnect: boolean;
    public appliancesLocation: boolean;
    public appliancesRefrigerator: boolean;
    public appliancesCooktop: boolean;
    public appliancesDishwasher: boolean;
    public appliancesOven: boolean;
    public refrigeratorOnsite: boolean;
    public cooktopMake: string;
    public cooktopModel: string;
    public bracket: boolean;
    public ovenFree: boolean;
    public ovenSlide: boolean;
    public ovenDrop: boolean;
    public materialBrand: boolean;
    public materialEdge: boolean;
    public materialSlab: boolean;
    public materialBacksplash: boolean;
    public materialOverhang: boolean;
    public materialSeam: boolean;
    public slab: boolean;
    public backsplash: boolean;
    public brand: string;
    public edge: string;
    public height: string;
    public overhang: string;
    public removalTypeInstaller: boolean;
    public removalTypeCustomer: boolean;
    public removalMaterial: boolean;
    public removalSecured: boolean;
    public material: string;
    public secured: string;
    public picturesOptions: string;
    public picturesNotes: string;
    public date: string;
    public signature: string;
    public environment: EnvironmentType;
    public retailOrderId: number;

    constructor(fields: Partial<PostTemplateChecklistDto>) {
        this.customerName = fields.customerName;
        this.contractorName = fields.contractorName;
        this.contractorSignature = fields.contractorSignature;
        this.cabinetryOptions = fields.cabinetryOptions;
        this.bracketsOptions = fields.bracketsOptions;
        this.sinksOptions = fields.sinksOptions;
        this.sinkMake = fields.sinkMake;
        this.sinkSupplied = fields.sinkSupplied;
        this.sinkSuppliedCustomer = fields.sinkSuppliedCustomer;
        this.sinkSuppliedInstaller = fields.sinkSuppliedInstaller;
        this.sinkModel = fields.sinkModel;
        this.installationOptions = fields.installationOptions;
        this.faucetOptions = fields.faucetOptions;
        this.faucetOnsite = fields.faucetOnsite;
        this.onsite = fields.onsite;
        this.faucetMake = fields.faucetMake;
        this.faucetModel = fields.faucetModel;
        this.holesQty = fields.holesQty;
        this.reveal = fields.reveal;
        this.plumbingTypeInstaller = fields.plumbingTypeInstaller;
        this.plumbingTypeCustomer = fields.plumbingTypeCustomer;
        this.plumbingDisconnect = fields.plumbingDisconnect;
        this.plumbingReconnect = fields.plumbingReconnect;
        this.appliancesLocation = fields.appliancesLocation;
        this.appliancesRefrigerator = fields.appliancesRefrigerator;
        this.appliancesCooktop = fields.appliancesCooktop;
        this.appliancesDishwasher = fields.appliancesDishwasher;
        this.appliancesOven = fields.appliancesOven;
        this.refrigeratorOnsite = fields.refrigeratorOnsite;
        this.cooktopMake = fields.cooktopMake;
        this.cooktopModel = fields.cooktopModel;
        this.bracket = fields.bracket;
        this.ovenFree = fields.ovenFree;
        this.ovenSlide = fields.ovenSlide;
        this.ovenDrop = fields.ovenDrop;
        this.materialBrand = fields.materialBrand;
        this.materialEdge = fields.materialEdge;
        this.materialSlab = fields.materialSlab;
        this.materialBacksplash = fields.materialBacksplash;
        this.materialOverhang = fields.materialOverhang;
        this.materialSeam = fields.materialSeam;
        this.slab = fields.slab;
        this.backsplash = fields.backsplash;
        this.brand = fields.brand;
        this.edge = fields.edge;
        this.height = fields.height;
        this.overhang = fields.overhang;
        this.removalTypeInstaller = fields.removalTypeInstaller;
        this.removalTypeCustomer = fields.removalTypeCustomer;
        this.removalMaterial = fields.removalMaterial;
        this.removalSecured = fields.removalSecured;
        this.material = fields.material;
        this.secured = fields.secured;
        this.picturesOptions = fields.picturesOptions;
        this.picturesNotes = fields.picturesNotes;
        this.date = fields.date;
        this.signature = fields.signature;
        this.environment = fields.environment;
        this.retailOrderId = fields.retailOrderId;
    }
}
