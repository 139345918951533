import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { AuthenticationTokenProvider, RefreshTokenProvider } from './providers';
import { AuthService, DeviceIdService, OtpService, UserStorageService } from './services';
import { AuthenticationResult } from '../../shared/token-interceptor/models/authentication-result.model';

@NgModule({
    declarations: [],
    imports: [HttpClientModule],
})
export class DomainAuthModule {
    public static forRoot(): ModuleWithProviders<DomainAuthModule> {
        return {
            ngModule: DomainAuthModule,
            providers: [
                AuthService,
                AuthenticationTokenProvider,
                RefreshTokenProvider,
                AuthenticationResult,
                UserStorageService,
                DeviceIdService,
                Device,
                OtpService,
            ],
        };
    }
}
