import { MangoQuerySortDirection } from 'rxdb';
import { AppointmentDateTypes, AppointmentsSearchParams } from '../../models';
import { KeywordsQueryExtender, QueryExtender, QueryModel, RetailersQueryExtender } from '../../queries';

export class SearchOrdersQueryBuilder {
    private dateTypes?: AppointmentDateTypes;
    // We can probably get rid of several query builders. Not sure if we should do it tho

    private readonly keywords?: string;
    private readonly retailers?: string[];

    constructor(params: Partial<AppointmentsSearchParams>) {
        this.dateTypes = params.dateTypes;
        this.keywords = params.keywords;
        this.retailers = params.retailers;
    }

    private static getInitializedQuery(): QueryModel {
        let queryModel: QueryModel = {
            query: {
                selector: { $and: [], $or: [] },
            },
            sort: {
                [Symbol.iterator]: 'asc',
            },
        };

        queryModel.sort.modifiedUnix = 'desc' as MangoQuerySortDirection;
        // this is required because the sort field has to be present in the selector, check documentation.
        // and always true condition is added
        // todo: extract in constant
        queryModel.query.selector.$and = [{ modifiedUnix: { $gt: -1000 } }];

        return queryModel;
    }

    public build(): QueryModel {
        let queryModel = SearchOrdersQueryBuilder.getInitializedQuery();
        const extenders: QueryExtender[] = [];
        extenders.push(new KeywordsQueryExtender(this.keywords));
        extenders.push(new RetailersQueryExtender(this.retailers));

        extenders.forEach((extender) => {
            queryModel = extender.extend(queryModel);
        });

        return queryModel;
    }
}
