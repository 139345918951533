import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackApiService } from './services';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [FeedbackApiService],
})
export class DomainFeedbackModule {}
