import { AppointmentDateBaseHandler } from './appointmentDateBase.handler';
import { AppointmentDate, AppointmentType } from '@domain/database';
import { AppointmentDateType, AppointmentDateTypes } from '../../models';

export class AppointmentCompleteDateHandler extends AppointmentDateBaseHandler {
    public handle(
        appointmentType: AppointmentType,
        appointmentDates: AppointmentDate[],
        dateType: AppointmentDateTypes
    ): number | null {
        if (dateType === AppointmentDateTypes.APPOINTMENT_COMPLETE_DATE) {
            let appointmentCompleteDate: AppointmentDate | undefined;

            if (appointmentType === AppointmentType.Measure) {
                appointmentCompleteDate = appointmentDates.find(
                    (x) => x.dateType === AppointmentDateType.ActualMeasureDate
                );
            }

            if (appointmentType === AppointmentType.Install) {
                appointmentCompleteDate = appointmentDates.find(
                    (x) => x.dateType === AppointmentDateType.ActualInstallDate
                );
            }

            if (appointmentType === AppointmentType.Delivery || appointmentType === AppointmentType.WorkOrder) {
                appointmentCompleteDate = appointmentDates.find((x) => x.dateType === AppointmentDateType.DeliveryDate);
            }

            return appointmentCompleteDate ? appointmentCompleteDate.dateUnix : null;
        }

        return super.handle(appointmentType, appointmentDates, dateType);
    }
}
