import { QueryExtender } from '../query.extender';
import { AppointmentDateTypes, AppointmentStatus } from '../../models';
import { getUnixTime, startOfToday } from 'date-fns';
import { QueryModel } from '../query-extender.model';

export class AlertsDateQueryExtender implements QueryExtender {
    private readonly dateType: AppointmentDateTypes | null;
    private readonly yesterdayUnixTimestamp: number;

    constructor(dateType: AppointmentDateTypes | null) {
        this.dateType = dateType;
        this.yesterdayUnixTimestamp = getUnixTime(startOfToday()) - 1;
    }

    public extend(finalQuery: QueryModel): QueryModel {
        if (Number(this.dateType) === AppointmentDateTypes.Alerts) {
            const rangeCondition = {
                status: { $in: [AppointmentStatus.Open] },
                filterableDate: { $lt: this.yesterdayUnixTimestamp },
            };

            finalQuery.query.selector.$and.push(rangeCondition);
        }

        return finalQuery;
    }
}
