import { EnvironmentType } from '../../database/models';

export class AppointmentSaveDateDto {
    public dateType: number;
    public date: string;
    public environment: EnvironmentType;
    public retailOrderId: number;

    constructor(fields: Partial<AppointmentSaveDateDto>) {
        this.dateType = fields.dateType;
        this.date = fields.date;
        this.environment = fields.environment;
        this.retailOrderId = fields.retailOrderId;
    }
}
